/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.validation-errors {
  font-size: 13px;
  color: #d00247;
}

.address-form label {
  font-weight: bold;
}

.react-datepicker-wrapper {
  display: block !important;
}

.button-distance {
  display: flex;
  justify-content: space-around;
}

.react-loading {
  background: #8360c3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #18a689, #8360c3); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #18a689,
    #8360c3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-size: 26px;
  height: 100vh !important;
}

.btn.btn-white.active {
  background-color: $navy;
  color: white;
}

.capitalize {
  text-transform: capitalize;
}

.nav-divider {
  border-bottom: 1px solid darken($nav-bg, 3%);
  background-color: darken($nav-bg, 3%);
  margin-top: 15px;
  margin-bottom: 15px;
}

.nav-dashboard {
  color: $nav-text-color;
  font-weight: 600;
  padding: 14px 20px 14px 25px;
  display: block;
}

.nav-dashboard .text-muted {
  color: #8095a8 !important;
}

.nav-dashboard > span > a {
  color: #a7b1c2 !important;
}

.nav-dashboard > i {
  margin-right: 6px;
}

.file-delete-icon {
  cursor: pointer;
  display: inline-block;
  margin: 5px 5px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.upload-result {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.label-cursor {
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 33px;
  left: 0px;
  will-change: top, left;
}

.dropdown-menu > li > a > i {
  margin-left: -10px !important;
}
