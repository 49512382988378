.upload-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.upload-card {
  background-color: white;
  padding: 32px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.upload-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.upload-form-content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.upload-files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.upload-actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.upload-filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.upload-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.upload-checkbox {
  opacity: 0.5;
  margin-left: 32px;
}

.upload-progress-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.upload-button {
  font-family: "Roboto medium", sans-serif;
  font-size: 14px;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: $navy;
  color: #fff;
  outline: 0;
}

.upload-button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}

.upload-progress-bar {
  width: 100%;
  height: 8px;
  background-color: $lazur;
  border-radius: 5px;
}

.upload-progress {
  background-color: $navy;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.upload-dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.upload-highlight {
  background-color: rgb(188, 185, 236);
}

.upload-icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.upload-file-input {
  display: none;
}
