.notificacao-icon-container {
  padding: 20px 10px;
}

.dropdown-menu > li > div.notificacao-item {
  border-radius: 3px;
  color: inherit;
  text-align: left;
  font-weight: normal;
  display: flex;
}

.notificacao-item {
  padding: 6px 20px;
  font-size: 13px;
}

.notificacao-item:hover {
  background-color: #f8f8f8;
  border-radius: 3px;
}

.notificacao-data {
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  align-items: center;
}

.msg-container {
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.notificacao-badge {
  position: relative;
}

.nova-notificacao {
  background-color: #f5f9fb;
}

.notificacao-badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -9px;
  right: -9px;
  font-size: 0.7em;
  background: #337ab7;
  color: #fff;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}

.notificacao-badge[data-badge='0']:after {
  content: none;
}

.notificacao-empty {
  height: 315px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
}

.m-0 {
  margin: 0 !important;
}

.bt-grey {
  border-top: 1px solid #dddfe2 !important;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.avatar-container {
  width: 80px;
}

.dot {
  height: 7px;
  width: 7px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot:hover {
  height: 7px;
  width: 7px;
  background-color: #828282;
  border-radius: 50%;
  display: inline-block;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.w-330 {
  width: 330px !important;
}

.w-400 {
  width: 400px !important;
}

.c-pointer {
  cursor: pointer !important;
}

.fix-h-337 {
  height: 337px !important;
  overflow: auto !important;
}

.grey {
  color: #999c9e !important;
}

.no-profile {
  display: flex;
  justify-content: center;
  background: #1ab394;
  font-size: 30px;
  color: #fff;
  align-items: center;
}

.fw-700 {
  text-transform: capitalize;
  font-weight: 700;
}

.text-lowercase {
  text-transform: lowercase;
}

img.avatar {
  object-fit: cover;
}

.xs-scroll::-webkit-scrollbar {
  width: 5px;
}

.xs-scroll::-webkit-scrollbar-track {
  background: #ddd;
}

.xs-scroll::-webkit-scrollbar-thumb {
  background: #666;
}
